<template>
    <div class="certification">
        <div class="title">
            <div class="sub-title">认证中心</div>
        </div>
        <el-card class="box-card">
            
            <div class="header flex">
                <div class="step" :class="[state==0?'cur':'']">1.填写信息</div>
                <div class="step" :class="[state==3?'cur':'']">2.等待审核</div>
                <div class="step" :class="[state===1||state==2?'cur':'']">3.审核结果</div>
            </div>
            <div class="list" v-if="state==0">
                <el-row>
                    <el-col :span="12">
                        <el-form ref="certificationFormRef" :model="certificationForm" :rules="certificationRules" label-width="110px">
                            <el-form-item label="企业名称: " prop="companyName">
                                <el-input v-model="certificationForm.companyName" placeholder="请填写企业名称" size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="所属行业: " prop="companyIndustryId">
                                <el-select v-model="certificationForm.companyIndustryId" placeholder="请选择公司所属行业">
                                    <el-option
                                    v-for="item in industryList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="企业统一信用代码: " prop="companyBusinessLicenseNumber">
                                <el-input v-model="certificationForm.companyBusinessLicenseNumber" placeholder="请填写企业统一信用代码" size="small"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="招聘负责人代表: " prop="legalName">
                                <el-input v-model="certificationForm.legalName" placeholder="请填写招聘负责人姓名" size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="招聘负责人身份证号: " prop="legalIdNumber">
                                <el-input v-model="certificationForm.legalIdNumber" placeholder="请填写招聘负责人身份证号码" size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="招聘负责人身份证：(正面) " prop="legalIdCardPre">
                                <upload-img @changeurl="changeurl" :modeldata="certificationForm"></upload-img>
                                <upload-img @changeurl="changeLegalIdCardPre" :picurl="certificationForm.legalIdCardPre"></upload-img>
                            </el-form-item>
                            <el-form-item label="招聘负责人身份证：(反面)" prop="legalIdCardAfter">
                                <upload-img @changeurl="changeLegalIdCardAfter" :picurl="certificationForm.legalIdCardAfter"></upload-img>
                            </el-form-item> -->
                            <el-form-item label="营业执照: " required>
                                <upload-img @changeurl="changeCompanyBusinessLicense" @uploading="uploading" :picurl="certificationForm.companyBusinessLicense"></upload-img>
                            </el-form-item> 
                            <el-form-item label="招聘负责人信息: " required>
                                <span class="legalPerson">{{certificationForm.legalName}}</span>
                                <el-button class="blur" size="small" @click="chooseLegalPerson">选择招聘负责人</el-button>
                                <!-- <el-button class="blur" size="small" @click="chooseLegalPerson" v-show="certificationForm.legalId<=0">选择招聘负责人</el-button> -->
                            </el-form-item>
                            <!-- <el-form-item label="营业期限: " prop="dataTime">
                                <span class="split">xxxx年xx月xx日 至 </span>
                                <el-date-picker
                                    v-model="certificationForm.dataTime"
                                    size="small"
                                    type="date"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item> -->
                        </el-form>
                    </el-col>
                </el-row>
                <el-button class="active button" @click="saveInfo">提 交</el-button>
            </div>
            <div class="review-open" v-if="state==3">
                <img src="../../../assets/img/company/review_open.png" alt="" srcset="">
            </div>
            <div class="review-close" v-if="state==2">
                <div><img src="../../../assets/img/company/review_close.png" alt="" srcset=""></div>
                <el-button class="active button" @click="reset">重新提交</el-button>
            </div>
            <el-dialog
                :title="legalPersonChoose?'选择招聘负责人':'新增招聘负责人'"
                :visible.sync="selectLegalPerson"
                width="30%">
                <div class="choose" v-show="legalPersonChoose">
                    <el-form ref="selectLegalPersonFormRef" :model="selectLegalPersonForm" :rules="selectLegalPersonFormRules" label-width="100px">
                        <el-form-item label="招聘负责人姓名" prop="legalName">
                            <el-input v-model="selectLegalPersonForm.legalName"></el-input>
                        </el-form-item>
                        <el-form-item label="招聘负责人身份证" prop="legalIdNumber">
                            <el-input v-model="selectLegalPersonForm.legalIdNumber"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="add" v-show="!legalPersonChoose">
                    <el-form ref="addLegalPersonFormRef" :model="addLegalPersonForm" :rules="addLegalPersonRules" label-width="110px">
                        <el-form-item label="招聘负责人姓名: " prop="legalName">
                            <el-input v-model="addLegalPersonForm.legalName" placeholder="请填写招聘负责人姓名" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="招聘负责人身份证(正面): " prop="legalIdCardPre">
                            <upload-img @changeurl="changeLegalIdCardPre" :picurl="addLegalPersonForm.legalIdCardPre" @uploading="uploading"></upload-img>
                        </el-form-item>
                        <el-form-item label="招聘负责人身份证(背面): " prop="legalIdCardAfter">
                             <upload-img @changeurl="changeLegalIdCardAfter" :picurl="addLegalPersonForm.legalIdCardAfter" @uploading="uploading"></upload-img>
                        </el-form-item>
                        <el-form-item label="招聘负责人身份证: " prop="legalIdNumber">
                            <el-input v-model="addLegalPersonForm.legalIdNumber" placeholder="请填写招聘负责人身份证号码" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="招聘负责人联系电话: " prop="phone">
                            <el-input v-model="addLegalPersonForm.phone" placeholder="请填写招聘负责人手机号码" size="small"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="selectLegalPerson = false">取 消</el-button>
                    <el-button type="primary"  :loading="legalLoading" @click="legalPersonTo">确 定</el-button>
                </span>
            </el-dialog>
            <video-tips v-if="videoStatus" :statusFlag="statusFlag"></video-tips>
        </el-card>
    </div>
</template>
<script>
import UploadImg from '../components/UploadImg'
import companyRequest from '../../../api/company'
import VideoTips from '../components/VideoTips'
// 验证身份证的规则
let checkCard = (rule, value, cb) => {
    const regCard = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

    if (regCard.test(value)) {
        return cb()
    }

    cb(new Error('请输入合法身份证号码'))
}
// 验证手机号的规则
let checkMobile = (rule, value, cb) => {
    const regMobile = /^[1]([3-9])[0-9]{9}$/

    if (regMobile.test(value)) {
    return cb()
    }

    cb(new Error('请输入合法的手机号'))
}
// 验证图片
let checkCardPic = (rule, value, cb) => {
    if (value) {
    return cb()
    }

    cb(new Error('请上传身份证'))
}
// 验证招聘负责人身份证是否存在
let checkLegalCardNumber = (rule, value, cb) => {
    companyRequest.checkLegalCardNumber({idNumber: value}).then((res) => {
        if (res.code === 200) {
            return cb()
        }
    }).catch((err) => {
         cb(new Error('招聘负责人身份信息已存在，请勿重复添加~'))
    })
    // if (value) {
    // return cb()
    // }

    // cb(new Error('请上传身份证'))
}
let checkLegal = (rule, value, cb) => {
    if (this.certificationForm.legalId !== null) {
        return cb()
    }

    cb(new Error('请选择招聘负责人'))
}
export default {
    created() {
        this.state = window.localStorage.getItem('authInfoStatus')
        // console.log('state', this.state)
        const user = JSON.parse(window.localStorage.getItem('userInfo'))
        // console.log('user', user)
        //获取招聘负责人信息
        if (user.legalId > 0) {
            this.certificationForm.legalId = user.legalId
            this.certificationForm.legalName = user.legalName
        }
    },
    components: {
        UploadImg,
        VideoTips
    },
     beforeCreate(){//组件生命周期函数
        console.log('beforeCreate')
    },
    mounted() {
        this.getIndustry()
    },
    beforeRouteEnter(to,from,next){
        next()
    },
    beforeRouteUpdate(to,from,next){
        next()
    },
    beforeRouteLeave(to,from,next){
         if (window.localStorage.getItem('authInfoStatus') == 0 || window.localStorage.getItem('authInfoStatus')== 2) {
             if (to.path == '/newLogin') {
                next()
             } else {
                 this.$alert('请先认证企业资质,再进入其他页面~', '企业认证', {
                    confirmButtonText: '确定',
                    callback: action => { 
                        // this.$message.warning('请先认证企业信息后,在进入其他页面~')
                        this.$store.commit('changeActivePath', {storeActivePath: '/company/mine'})
                    }
                })
                // console.log(1212)
                return
             }
            // this.$alert('请先认证企业资质,再进入其他页面~', '企业认证', {
            //     confirmButtonText: '确定',
            //     callback: action => { 
            //         // this.$message.warning('请先认证企业信息后,在进入其他页面~')
            //     }
            // })
            // return
        } else {
            next()
        }
        
    },
    data() {
        return {
            industryList: [],
            legalLoading: false, //招聘负责人操作
            selectLegalPerson: false, //选择招聘负责人弹出对话框
            legalPersonChoose: true, //添加选择招聘负责人动态切换
            certificationForm: { //企业认证表单
                companyName: '', //公司名称
                legalName: '', //招聘负责人姓名
                companyIndustryId:'', //所属行业
                legalId: null, //招聘负责人id
                legalName: '', //招聘负责人姓名
                // legalIdCardPre: '', //招聘负责人身份证正面
                // legalIdCardAfter: '', //招聘负责人身份证反面
                // legalIdNumber: '', //招聘负责人身份证
                companyBusinessLicense: '', //营业执照,
                companyBusinessLicenseNumber: '' //注册编号
                
            },
            selectLegalPersonForm: { //选择招聘负责人表单
                legalName: '', //姓名
                legalIdNumber: '' //身份证
            },
            addLegalPersonForm: { //添加招聘负责人表单
                legalName: '', //招聘负责人姓名
                legalIdCardPre: '', //招聘负责人身份证（正）
                legalIdCardAfter: '', //招聘负责人身份证（反）
                legalIdNumber: '', //招聘负责人身份证号码
                phone: '' //招聘负责人手机号码

            },
            certificationRules: { //企业认证表单规则
                companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                // legalName: [{ required: true, message: '请输入招聘负责人姓名', trigger: 'blur' }],
                // legalIdCardPre: [{ required: true, message: '请上传招聘负责人身份证正面照片', trigger: 'change' }],
                // legalIdCardAfter: [{ required: true, message: '请上传招聘负责人身份证反面照片', trigger: 'change' }],
                // companyBusinessLicense: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
                legalIdNumber: [{ required: true, message: '请填写招聘负责人身份证', trigger: 'blur' },
                // { validator: checkCard, trigger: 'blur' }
                ],
                
                companyBusinessLicenseNumber: [{ required: true, message: '请填写企业注册机构码', trigger: 'blur' }],
                companyIndustryId:  [{ required: true, message: '请选择公司所属行业', trigger: 'change' }],
                // legalId:  [{ required: true, message: '请选择招聘负责人', trigger: 'change' }, { validator: checkLegal, trigger: 'blur' }]
            },
            selectLegalPersonFormRules: { //选择招聘负责人表单规则
                legalName: [{ required: true, message: '请填写招聘负责人姓名', trigger: 'blur' }],
                legalIdNumber: [{ required: true, message: '请填写招聘负责人身份证号码', trigger: 'blur' }, { validator: checkCard, trigger: 'blur' }],
            },
            addLegalPersonRules: { //添加招聘负责人表单规则
                legalName: [{ required: true, message: '请填写招聘负责人姓名', trigger: 'blur' }],
                legalIdCardPre: [{ required: true, message: '请上传招聘负责人身份证正面照片', trigger: 'blur' }],
                legalIdCardAfter: [{ required: true, message: '请上传招聘负责人身份证背面照片', trigger: 'blur' }],
                legalIdNumber: [{ required: true, message: '请填写招聘负责人身份证号码', trigger: 'blur' }, { validator: checkCard, trigger: 'blur' }, { validator: checkLegalCardNumber, trigger: 'blur' }],
                phone: [{ required: true, message: '请填写招聘负责人手机号码', trigger: 'blur' }, { validator: checkMobile, trigger: 'blur' }],
            },
            state: null,
            videoStatus: false,
            statusFlag: 1,
        }
    },
    methods: {
        //获取招聘负责人身份证正面图片地址
        changeLegalIdCardPre(url) {
            this.addLegalPersonForm.legalIdCardPre = url
            // console.log('legalIdCardPre', this.certificationForm.legalIdCardPre)
            this.statusFlag = 2
            setTimeout(() => {
                this.videoStatus = false
            }, 400)
            this.$message.success('图片上传成功')

        },
        //打开选择招聘负责人对话框
        chooseLegalPerson() {
            this.selectLegalPerson = true
            this.legalPersonChoose = true
            this.legalLoading = false
            this.selectLegalPersonForm.legalName = ''
            this.selectLegalPersonForm.legalIdNumber = ''
            this.addLegalPersonForm.legalName = ''
            this.addLegalPersonForm.legalIdCardPre = ''
            this.addLegalPersonForm.legalIdCardAfter = ''
            this.addLegalPersonForm.legalIdNumber = ''
            this.addLegalPersonForm.phone = ''
            // this.$refs.selectLegalPersonFormRef.resetFields()
            // this.$refs.addLegalPersonFormRef.resetFields()
        },
        //选择或添加招聘负责人
        legalPersonTo() {
            if (this.legalPersonChoose) {
                // console.log('选择招聘负责人')
                // this.legalPersonChoose = false
                this.$refs.selectLegalPersonFormRef.validate((valid) => {
                    if (valid) {
                        this.legalLoading = true
                        companyRequest.queryLegal(this.selectLegalPersonForm).then((res) => {
                            if (res.code === 200) {
                                this.$message.success('已查询到相应招聘负责人信息')
                                this.certificationForm.legalName = res.data.legalName
                                this.certificationForm.legalId = res.data.id
                                this.legalLoading = false
                                this.selectLegalPerson = false
                            }
                        }).catch((err) => {
                            console.log('err', err)
                            if (!err.success) {
                                this.$message.warning('暂未查询到相关招聘负责人,请先添加招聘负责人~')
                                this.addLegalPersonForm.legalName = this.selectLegalPersonForm.legalName
                                this.addLegalPersonForm.legalIdNumber = this.selectLegalPersonForm.legalIdNumber
                                this.legalPersonChoose = false
                                this.legalLoading = false
                            }
                            
                        })
                    } else {
                        // console.log('error submit!!')
                        return false
                    }
                })
            } else {
                // console.log('添加招聘负责人', this.addLegalPersonForm)
                this.$refs.addLegalPersonFormRef.validate((valid) => {
                    if (valid) {
                        this.legalLoading = true
                        // console.log('添加招聘负责人', this.addLegalPersonForm)
                        companyRequest.addLegal(this.addLegalPersonForm).then((res) => {
                            if (res.code === 200) {
                                this.certificationForm.legalName = res.data.legalName
                                this.certificationForm.legalId = res.data.id
                                this.$message.success('添加成功')
                                // this.addLegalPersonFormRef.resetFields()
                                this.legalLoading = false
                                this.selectLegalPerson = false
                            }
                        }).catch((err) => {
                            this.legalLoading = false
                            this.$message.error('添加失败~')
                        })
                    } else {
                        // console.log('error submit!!')
                        return false
                    }
                })
            }
        },
        //获取公司行业分类
        getIndustry() {
            companyRequest.getIndustry().then((res) => {
                if (res.code === 200) {
                    this.industryList = res.data
                }
            })
        },
         //获取招聘负责人身份证反面图片地址
        changeLegalIdCardAfter(url) {
            this.addLegalPersonForm.legalIdCardAfter = url
            this.statusFlag = 2
            setTimeout(() => {
                this.videoStatus = false
            }, 400)
            // console.log('url', this.certificationForm.url)
            this.$message.success('图片上传成功')
        },
         //获取营业执照图片地址
        changeCompanyBusinessLicense(url) {
           this.certificationForm.companyBusinessLicense = url
            // console.log('url', this.certificationForm.url)
            this.$message.success('图片上传成功') 
            this.statusFlag = 2
            setTimeout(() => {
                this.videoStatus = false
            }, 400)
            
        },
        uploading() {
            this.videoStatus = true
            this.statusFlag = 1
        },
        reset() {
            this.state = 0 
            window.localStorage.setItem('authInfoStatus', 0)
        },
        //提交企业认证信息
        saveInfo() {
            // console.log('certificationForm', this.certificationForm)
            this.$refs.certificationFormRef.validate((valid) => {
                if (valid) {
                    if (!this.certificationForm.legalId) {
                        this.$message.error('请先选择招聘负责人')
                        return
                    }
                    if (!this.certificationForm.companyBusinessLicense) {
                        this.$message.error('请先上传营业执照')
                        return
                    }
                    // console.log('certificationForm', this.certificationForm)
                    companyRequest.addAuthInfo(this.certificationForm).then((res) => {
                        if (res.code === 200) {
                            //状态改为待审核
                            this.state = 3
                            window.localStorage.setItem('authInfoStatus', 3)
                            this.$message.success('提交成功')
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/company/companyInfo'
                                })
                            }, 1500)
                        }
                    })
                } else {
                    // console.log('error submit!!')
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.certification{
    height: 100%;
    .split{
        color: #606266;
        margin-right: 5px;
    }
    .blur{
        background: $blur;
        color: #fff;
        border: none;
    }
    .box-card{
        padding-bottom: 30px;
    }
    .header{
        padding: 15px 0 15px 60px;
        margin-bottom: 20px;
        .step{
            width: 200px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #999;
            font-size: 16px;
            margin-left: -10px;
            background: url('../../../assets/img/company/arrow2.png') no-repeat;
            &.cur{
                color: #fff;
                background: url('../../../assets/img/company/arrow1_1.png') no-repeat;
            }
        }
    }
    .list{
        padding-left: 40px;
        .button{
            width: 270px;
            margin-left: 110px;
        }
    }
    .el-select{
        width: 100%;
    }
    .review-open{
        margin: 100px 250px;
    }
    .review-close{
        margin: 100px 250px;
        .button{
            width: 200px;
            margin-top: 50px;
            margin-left: 60px;
        }
    }
    .legalPerson{
        color: #111;
        margin-right: 5px;
        font-weight: 700;
    }
}
</style>